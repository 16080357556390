<template>
    <div>
        <v-flex xs12 sm8 offset-sm-2 class="my-4 px-8">
        <v-card class="white--text mb-1 mt-8 " flat :tile="true">
          <v-btn
            fab
            small
            color="primary accent-2"
            bottom
            right
            absolute
            @click="goHome"
          >
            <v-icon>mdi-home</v-icon>
          </v-btn>
  
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs7>
                <div>
                  <div class="display-1 pl-10 black--text text--darken-1 ">Get in touch with us</div>
  <!--                <div class="grey&#45;&#45;text">-->
  <!--                    Your one stop shop for all poultry products-->
  <!--                </div>-->
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
  
        <v-card color="white mb-15" flat class="white--text pa-15" height="100%">
  
          <!--comes here-->
          <ValidationObserver
              ref="observer"
              v-slot="{ invalid }"
          >
            <v-form @submit.prevent="sendEmail">
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <v-text-field
                        v-model="name"
                        :error-messages="errors"
                        label="Name"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <ValidationProvider
                      v-slot="{ errors }"
                      name="email"
                      rules="required|email"
                  >
                    <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        label="E-mail"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider
                      v-slot="{ errors }"
                      name="message"
                      rules="required"
                  >
                    <v-textarea
                        v-model="message"
                        label="Message"
                        :error-messages="errors"
                    >
                    </v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center">
                  <v-btn
                      class="me-4 px-15 primary"
                      type="submit"
                  >
                    submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-flex>
    </div>
  </template>
  
  <script>
  import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
  import {required, email} from "vee-validate/dist/rules";
  import store from "@/store";
  import axios from "axios";

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('email', {
    ...email,
    message: 'Invalid email format'
  })
  export default {
    name: "contact_us",

    components: {
      ValidationObserver,
      ValidationProvider
    },
  
    data: () => ({
      items: [
        { txt: "We are Akokomarket", icon: "mdi-car-sports", set: true },
        { txt: "Our Management Team", icon: "mdi-account", set: false }
      ],
      name: '',
      email: '',
      message: '',
      text: "",
      gold_mgt: true,
      henry: false,
      felix: false,
      violet: false,
      enyo: false
    }),
  
    methods: {
      sendEmail(){
        this.$refs.observer.validate().then((res) => {
          if(res){
            axios.post('https://us-central1-akokomarket-d5e1f.cloudfunctions.net/sendEmail', {
              name: this.name,
              email: this.email,
              message: this.message,
            })
                .then(response => {
                  // Handle the response, e.g., show a success message
                  console.log('Email sent successfully');
                })
                .catch(error => {
                  // Handle any errors, e.g., show an error message
                  console.error('Error sending email:', error);
                });
          }
        }).catch((error) => {
              store.commit('setSnack', {
                color: 'red',
                status_msg: 'Error',
                added_msg: error.message,
                icon: 'mdi-information'
              })
            })
      },
      goHome() {
        this.$router.push("/");
      },
    //   addItem(item) {
    //     const removed = this.items.splice(0, 1);
    //     this.items.push(...this.more.splice(this.more.indexOf(item), 1));
    //     this.more.push(...removed);
    //     this.$nextTick(() => {
    //       this.currentItem = "tab-" + item;
    //     });
    //     alert(this.currentItem);
    //   }
    },
  };
  </script>
  
  <style scoped></style>
  