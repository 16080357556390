var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-flex',{staticClass:"my-4 px-8",attrs:{"xs12":"","sm8":"","offset-sm-2":""}},[_c('v-card',{staticClass:"white--text mb-1 mt-8 ",attrs:{"flat":"","tile":true}},[_c('v-btn',{attrs:{"fab":"","small":"","color":"primary accent-2","bottom":"","right":"","absolute":""},on:{"click":_vm.goHome}},[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs7":""}},[_c('div',[_c('div',{staticClass:"display-1 pl-10 black--text text--darken-1 "},[_vm._v("Get in touch with us")])])])],1)],1)],1),_c('v-card',{staticClass:"white--text pa-15",attrs:{"color":"white mb-15","flat":"","height":"100%"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Message","error-messages":errors},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"me-4 px-15 primary",attrs:{"type":"submit"}},[_vm._v(" submit ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }